import React from 'react';
import Edit from '../library/EditAndDelete/edit';
import Delete from '../library/EditAndDelete/delete';

interface UserRowProps {
  name: string | null;
  email: string | null;
  role: string | null;
  status: string | null;
}

const UserRow: React.FC<UserRowProps> = ({ name, email, role, status }) => {
  return (
    <tr>
      <td className="px-6 py-4 text-center">
        {' '}
        <p> {name} </p>{' '}
      </td>
      <td className="px-6 py-4 text-center">
        {' '}
        <p className=""> {email} </p>{' '}
      </td>
      <td className="px-6 py-4 text-center">
        {' '}
        <p className=""> {role} </p>{' '}
      </td>
      <td className="px-6 py-4 text-center">
        {' '}
        <p className=""> {status} </p>{' '}
      </td>
      <td className="pl-[48px] py-4 text-center">
        {' '}
        <Edit /> <Delete />{' '}
      </td>
    </tr>
  );
};

export default UserRow;
