import React, { Fragment } from 'react';
import Edit from '../../library/EditAndDelete/edit';
import Search from './Search';
import Show from './Show';

const ModuleList: React.FC<any> = () => {
  return (
    <Fragment>
      <div style={{ width: '100%' }}>
        <div className="bg-white" style={{ paddingInline: '2px 2px', width: '100%' }}>
          <h1 className="text-black text-xl leading-tight font-medium mb-2">Indigo Modules</h1>
          <br />
          <hr />
          <br />
          <Search />
          <Show />
          <br />
          <br />
          <div className="w-full">
            <table className="mx-auto max-w-4xl w-full whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300">
              <thead className="bg-[#f8f8f8]">
                <tr className="text-black text-left">
                  <th className="font-semibold text-sm uppercase px-6 py-4"> Module Name </th>
                  <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                    {' '}
                    Access level{' '}
                  </th>
                  <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                    {' '}
                    Action{' '}
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                <tr>
                  <td className="px-6 py-4">
                    {' '}
                    <p> Indigo Core </p>{' '}
                  </td>
                  <td className="px-6 py-4 text-center">
                    {' '}
                    <p className=""> NO ACCESS </p>{' '}
                  </td>
                  <td className="pl-[38px] py-4 text-center">
                    {' '}
                    <Edit />{' '}
                  </td>
                </tr>
                <tr>
                  <td className="px-6 py-4">
                    {' '}
                    <p> Indigo Core </p>{' '}
                  </td>
                  <td className="px-6 py-4 text-center">
                    {' '}
                    <p className=""> READ ONLY </p>{' '}
                  </td>
                  <td className="pl-[38px] py-4 text-center">
                    {' '}
                    <Edit />{' '}
                  </td>
                </tr>
                <tr>
                  <td className="px-6 py-4">
                    {' '}
                    <p> Indigo Core </p>{' '}
                  </td>
                  <td className="px-6 py-4 text-center">
                    {' '}
                    <p className=""> WRITE </p>{' '}
                  </td>
                  <td className="pl-[38px] py-4 text-center">
                    {' '}
                    <Edit />{' '}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ModuleList;
