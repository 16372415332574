import React from 'react';
import { useForm } from 'react-hook-form';
import ImageTag from '../library/ImageTag/ImageTag';
import AwsCognito from '../utils/AwsCognito';
import queryString from 'query-string';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import login from '../assets/img/login.png';

const ConfirmRegistration: React.FC<any> = (props: any) => {
  const navigate: NavigateFunction = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const { username } = queryString.parse(useLocation().search);
  const onSubmit = async (data: any) => {
    const awsCognitoObj = new AwsCognito();
    awsCognitoObj.confirmRegistration(String(username ?? ''), data.otp, (result: any) => {
      if (result.status === 201) {
        alert('Your account is activated..');
        if (result.status === 201) {
          const path = '/login';
          navigate(path);
        } else {
          alert('Username or password not valid..');
        }
      } else {
        alert('Confirm Registration Fail');
      }
    });
  };

  // utility function async for react callback properties when void expected
  function wrapAsyncFunction<ARGS extends unknown[]>(
    fn: (...args: ARGS) => Promise<unknown>
  ): (...args: ARGS) => void {
    return (...args) => {
      void fn(...args);
    };
  }

  return (
    <div>
      <div className="m-0 p-0 align-baseline">
        <div className="z-10 pt-3.5 min-h-[900px]  clear-both w-full relative overflow-hidden">
          {/* Header start */}
          <Header />
          <div
            className="w-full  absolute z-50 mt-0 clear-both h-px"
            style={{
              borderTop: '1px solid #ccc'
            }}
          ></div>
          <div className="clear-both"></div>
          {/* Border End */}
          {/* Login Panel start */}
          <div className="w-[980px] mx-auto mt-0 mb-0 px-4 min-h-[380px] min-w-[288px] table z-[5] relative top-[100px]">
            <div className="block p-[0.5rem] rounded-lg shadow-lg mt-[129px] my-8 mx-[19.625rem] pointer-events-auto float-none justify-center  bg-white">
              <form
                className="my-form"
                method="post"
                action=""
                name="frm_login"
                onSubmit={wrapAsyncFunction(handleSubmit(onSubmit))}
              >
                <div className="p-4">
                  {/* loginsection start */}
                  <div className="w-full">
                    <div className="text-[22px] text-center mb-[15px]">OTP</div>
                    <label htmlFor="validationCustomUsername" className="inline mb-0 mt-2">
                      {errors.otp == null ? 'Enter OTP' : <span>Please enter your OTP</span>}
                    </label>

                    <input
                      className="h-7 w-full p-1.5   text-slate-400 rounded-l rounded-b border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px]"
                      type="text"
                      {...register('otp', {
                        required: true
                      })}
                      style={
                        errors.otp == null
                          ? { border: '1px solid #666' }
                          : { border: '1px solid #e60014' }
                      }
                    ></input>
                  </div>
                  {/* loginsection End */}
                  <label className="clear-left text-[#333] mt-1.5 border border-border-grey pb-2.5 hidden"></label>
                  <p className="mb-4 clear-both text-xs pb-0 mr-0 ml-0 mt-0"></p>
                  <input
                    type="submit"
                    value="Submit"
                    className="p-0 tracking-normal mb-4 h-10 text-[#fff] text-[17px] w-full shadow-none border border-login-bt-border bg-black rounded-[3px] cursor-pointer"
                  ></input>
                </div>
              </form>
            </div>
          </div>
          {/* Login Panel End */}
          {/* Background Image start */}
          <div className="w-full mt-0 mr-auto mb-0  min-h-[64.000em] absolute top-[91px] text-center z-0  block">
            {/* <img src={login}></img> */}
            <ImageTag
              src={login}
              className="relative object-cover bg-center bg-cover bg-repeat"
              alt="Home"
              height="auto"
              width="100%"
            />
          </div>
          {/* Background Image End */}
        </div>
      </div>
      {/* Footer Start */}
      <div className="z-[9999]">
        <Footer />
      </div>
      {/* Footer End */}
    </div>
  );
};
export default ConfirmRegistration;
