import React, { Fragment, useEffect, useRef, useState } from 'react';
import Button from '../../library/Button/button';
import InputField from '../../library/inputField/inputField';
import Label from '../../library/label/label';

const UserDetails: React.FC<any> = (props: any) => {
  interface IError {
    errorMessage: string | null;
    errorConfirmMessahe: string | null;
  }
  // First Name Validation
  const [isfNValid, setisfNValid] = useState<boolean>(false);
  const [isFnameEnable, setFnameEnable] = useState<boolean>(false);
  const [errfN, setErrfN] = useState<IError>({
    errorMessage: null,
    errorConfirmMessahe: null
  });
  useEffect(() => {
    document.addEventListener('click', handle_Changes, isFnameEnable);
    return () => {
      document.removeEventListener('click', handle_Changes, isFnameEnable);
    };
  }, [isFnameEnable]);
  const click_Ref_1: any = useRef(null);
  function isValidfN(fn: string): boolean {
    const regex = /^[a-z ,.'-]+$/i;
    return regex.test(fn);
  }
  const handle_Changes = (event: any): any => {
    if (click_Ref_1.current && !click_Ref_1.current.contains(event.target) && isFnameEnable) {
      if (!isValidfN(click_Ref_1.current.value)) {
        setisfNValid(false);
        setErrfN({
          errorMessage: 'Please provide a valid first name.',
          errorConfirmMessahe: ''
        });
      } else {
        setisfNValid(true);
        setErrfN({ errorMessage: null, errorConfirmMessahe: null });
      }
    } else {
      setErrfN({ errorMessage: null, errorConfirmMessahe: null });
    }
  };
  const handleOn_Changes = (event: any): any => {
    if (event.target.value !== undefined) {
      if (event.target.id === 'fn') {
        props.setFname(event.target.value);
        isValidfN(event.target.value) ? setisfNValid(true) : setisfNValid(false);
        setErrfN({
          errorMessage: null,
          errorConfirmMessahe: errfN.errorConfirmMessahe
        });
      } else {
        props.setFname(props.fname);
        setErrfN({
          errorMessage: errfN.errorMessage,
          errorConfirmMessahe: null
        });
      }
    }
  };
  // Last Name Validation
  const [islNValid, setislNValid] = useState<boolean>(false);
  const [isLnameEnable, setLnameEnable] = useState<boolean>(false);
  const [errlN, setErrlN] = useState<IError>({
    errorMessage: null,
    errorConfirmMessahe: null
  });
  useEffect(() => {
    document.addEventListener('click', handle_changes, isLnameEnable);
    return () => {
      document.removeEventListener('click', handle_changes, isLnameEnable);
    };
  }, [isLnameEnable]);
  const click_Ref_2: any = useRef(null);
  function isValidlN(ln: string): boolean {
    const regex = /^[a-z ,.'-]+$/i;
    return regex.test(ln);
  }
  const handle_changes = (event: any): any => {
    if (click_Ref_2.current && !click_Ref_2.current.contains(event.target) && isLnameEnable) {
      if (!isValidlN(click_Ref_2.current.value)) {
        setislNValid(false);
        setErrlN({
          errorMessage: 'Please provide a valid last name.',
          errorConfirmMessahe: ''
        });
      } else {
        setislNValid(true);
        setErrlN({ errorMessage: null, errorConfirmMessahe: null });
      }
    } else {
      setErrlN({ errorMessage: null, errorConfirmMessahe: null });
    }
  };
  const handleOn_changes = (event: any): any => {
    if (event.target.value !== undefined) {
      if (event.target.id === 'ln') {
        props.setLname(event.target.value);
        isValidlN(event.target.value) ? setislNValid(true) : setislNValid(false);
        setErrlN({
          errorMessage: null,
          errorConfirmMessahe: errlN.errorConfirmMessahe
        });
      } else {
        props.setLname(props.lname);
        setErrlN({
          errorMessage: errlN.errorMessage,
          errorConfirmMessahe: null
        });
      }
    }
  };
  // Job Details validation
  const [isJobValid, setisJobValid] = useState<boolean>(false);
  const [isJobEnable, setJobEnable] = useState<boolean>(false);
  const [errJob, setErrJob] = useState<IError>({
    errorMessage: null,
    errorConfirmMessahe: null
  });
  useEffect(() => {
    document.addEventListener('click', handle_chng, isJobEnable);
    return () => {
      document.removeEventListener('click', handle_chng, isJobEnable);
    };
  }, [isJobEnable]);
  const click_ref_3: any = useRef(null);
  function isValidJob(j: string): boolean {
    const regex = /^(?!\s*$).+/;
    return regex.test(j);
  }
  const handle_chng = (event: any): any => {
    if (click_ref_3.current && !click_ref_3.current.contains(event.target) && isJobEnable) {
      if (!isValidJob(click_ref_3.current.value)) {
        setisJobValid(false);
        setErrJob({
          errorMessage: "Job title can't be empty.",
          errorConfirmMessahe: ''
        });
      } else {
        setisJobValid(true);
        setErrJob({ errorMessage: null, errorConfirmMessahe: null });
      }
    } else {
      setErrJob({ errorMessage: null, errorConfirmMessahe: null });
    }
  };
  const handleOn_chng = (event: any): any => {
    if (event.target.value !== undefined) {
      if (event.target.id === 'job') {
        props.setjobTitle(event.target.value);
        isValidJob(event.target.value) ? setisJobValid(true) : setisJobValid(false);
        setErrJob({
          errorMessage: null,
          errorConfirmMessahe: errJob.errorConfirmMessahe
        });
      } else {
        props.setjobTitle(props.jobTitle);
        setErrJob({
          errorMessage: errJob.errorMessage,
          errorConfirmMessahe: null
        });
      }
    }
  };
  // Phone number validation
  const [isphValid, setisphValid] = useState<boolean>(false);
  const [isPhEnable, setPhEnable] = useState<boolean>(false);
  const [errPh, setErrPh] = useState<IError>({
    errorMessage: null,
    errorConfirmMessahe: null
  });
  useEffect(() => {
    document.addEventListener('click', handle_change, isPhEnable);
    return () => {
      document.removeEventListener('click', handle_change, isPhEnable);
    };
  }, [isPhEnable]);
  const click_ref_4: any = useRef(null);
  function isValidph(p: string): boolean {
    const regex = /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/;
    return regex.test(p);
  }
  const handle_change = (event: any): any => {
    if (click_ref_4.current && !click_ref_4.current.contains(event.target) && isPhEnable) {
      if (!isValidph(click_ref_4.current.value)) {
        setisphValid(false);
        setErrPh({
          errorMessage: 'Please provide a valid phone number.',
          errorConfirmMessahe: ''
        });
      } else {
        setisphValid(true);
        setErrPh({ errorMessage: null, errorConfirmMessahe: null });
      }
    } else {
      setErrPh({ errorMessage: null, errorConfirmMessahe: null });
    }
  };
  const handleOn_change = (event: any): any => {
    if (event.target.value !== undefined) {
      if (event.target.id === 'ph') {
        props.setPhone(event.target.value);
        isValidph(event.target.value) ? setisphValid(true) : setisphValid(false);

        setErrPh({
          errorMessage: null,
          errorConfirmMessahe: errPh.errorConfirmMessahe
        });
      } else {
        props.setPhone(props.phone.ph);
        setErrPh({
          errorMessage: errPh.errorMessage,
          errorConfirmMessahe: null
        });
      }
    }
  };

  return (
    <Fragment>
      <div className={!props.isSaveUserDetails ? 'block mb-[30px]' : 'block'}>
        <h2
          className={
            !props.isSaveUserDetails
              ? 'py-5 px-[30px] my-0 -mx-[30px] bg-[#666] text-white cursor-pointer text-[1em] z-[1] min-h-[45px]'
              : 'py-5 px-[30px] my-0 -mx-[30px] bg-[#008A00] text-white cursor-pointer text-[1em] z-[1] min-h-[45px]'
          }
          style={{ borderTop: '1px solid #fff' }}
        >
          <span className="text-white block">
            Your details
            {props.isSaveUserDetails && <span> - </span>}
            {props.isSaveUserDetails ? `${props.title} ${props.fname} ${props.lname}` : ''}
          </span>
          {props.isSaveUserDetails && (
            <span className="block float-right h-4 text-left">
              <span
                className="flot-left py-4 px-[5px] text-[white] inline-block relative -top-[49px] underline"
                onClick={() => {
                  props.setSaveUserDetails(false);
                }}
              >
                Edit
              </span>
              <span
                className="inline-block relative -top-[34px]"
                style={{ transform: 'translate(0%,1%)' }}
              >
                <span
                  className="bg-[100%] bg-[url('https://my.bt.com/s/apps/appsconnectedcare/img/BT_Iconography_Confirmation_message_white.png')] h-12 w-12 overflow-hidden inline-block"
                  style={{
                    backgroundPosition: '0 0',
                    backgroundSize: '100% 100%'
                  }}
                ></span>
              </span>
            </span>
          )}
        </h2>
      </div>
      {!props.isSaveUserDetails && (
        <div className="block">
          <h3 className="pr-[25%] mt-[14px] mb-2.5 text-[21px] leading-[1.5em] text-[#333]">
            Please enter your personal details
          </h3>

          <div className="block">
            <div className="p-0 w-full float-left relative">
              <div className="w-full my-5 mx-0 relative table border-separate">
                <Label
                  className="text-right leading-[40px] pr-2.5 pl-0 w-[31.25%] mb-[5px] float-left"
                  LabelText=" Title"
                />
                <div className="px-0 w-[31.25%] table">
                  <select
                    id="title"
                    value={props.title}
                    onChange={(e) => {
                      props.setTitle(e.target.value);
                    }}
                    className="w-full bg-[url('https://my.bt.com/s/apps/appsconnectedcare/img/br_down.png')] bg-[32px] h-10 py-[0.1em] rounded-[5px] pl-[5px] text-[#555] pr-[33px] m-0 bg-no-repeat bg-center overflow-hidden appearance-none"
                    style={{
                      backgroundPosition: 'right',
                      border: '1px solid #666',
                      backgroundSize: '32px'
                    }}
                    required={true}
                  >
                    <option value="" label=""></option>
                    <option value="Mr" label="Mr">
                      Mr
                    </option>
                    <option value="Mrs" label="Mrs">
                      Mrs
                    </option>
                    <option value="Miss" label="Miss">
                      Miss
                    </option>
                    <option value="Ms" label="Ms">
                      Ms
                    </option>
                    <option value="Dr" label="Dr">
                      Dr
                    </option>
                    <option value="Professor" label="Professor">
                      Professor
                    </option>
                    <option value="Sir" label="Sir">
                      Sir
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full my-5 mx-0 relative table border-separate">
            <Label
              className="text-right leading-[40px] pr-2.5 pl-0 w-[31.25%] mb-[5px] float-left"
              LabelText="First name"
            />
            <div className="px-0 w-[31.25%] table">
              <InputField
                id="fn"
                type="text"
                className="h-10 block w-full py-[7px] px-[14px] rounded bg-white test-[#555]"
                value={props.fname}
                onChange={handleOn_Changes}
                reference={click_Ref_1}
                required={true}
                onClick={() => {
                  setFnameEnable(true);
                }}
                style={
                  errfN.errorMessage == null
                    ? { border: '1px solid #666' }
                    : { border: '1px solid #e60014' }
                }
              />
              {errfN.errorMessage && (
                <div className="h-auto w-full float-left bg-[#E60014] text-white mt-2.5 relative table mb-[15px]">
                  <span className="px-0 bg-[url('https://my.bt.com/s/apps/appsconnectedcare/img/up_icon_red.png')] bg-no-repeat absolute -top-[10px] left-2.5 w-[24px] h-[11px] mt-0 mr-0 mb-2.5 ml-2.5"></span>
                  <div className="pt-2.5 pr-2.5 pb-2.5 pl-[15px]">
                    <span className="left-[15px] py-0 h-10 w-10 m-0 text-[.95em]">
                      {' '}
                      {errfN.errorMessage}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="w-full my-5 mx-0 relative table border-separate">
            <Label
              className="text-right leading-[40px] pr-2.5 pl-0 w-[31.25%] mb-[5px] float-left"
              LabelText="Last name"
            />
            <div className="px-0 w-[31.25%] table">
              <InputField
                id="ln"
                type="text"
                className="h-10 block w-full py-[7px] px-[14px] rounded bg-white test-[#555]"
                value={props.lname}
                onChange={handleOn_changes}
                reference={click_Ref_2}
                required={true}
                style={
                  errlN.errorMessage == null
                    ? { border: '1px solid #666' }
                    : { border: '1px solid #e60014' }
                }
                onClick={() => {
                  setLnameEnable(true);
                }}
              />
              {errlN.errorMessage && (
                <div className="h-auto w-full float-left bg-[#E60014] text-white mt-2.5 relative table mb-[15px]">
                  <span className="px-0 bg-[url('https://my.bt.com/s/apps/appsconnectedcare/img/up_icon_red.png')] bg-no-repeat absolute -top-[10px] left-2.5 w-[24px] h-[11px] mt-0 mr-0 mb-2.5 ml-2.5"></span>
                  <div className="pt-2.5 pr-2.5 pb-2.5 pl-[15px]">
                    <span className="left-[15px] py-0 h-10 w-10 m-0 text-[.95em]">
                      {' '}
                      {errlN.errorMessage}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="w-full my-5 mx-0 relative table border-separate">
            <Label
              className="text-right leading-[40px] pr-2.5 pl-0 w-[31.25%] mb-[5px] float-left"
              LabelText=" Job title"
            />

            <div className="px-0 w-[31.25%] table">
              <InputField
                id="job"
                type="text"
                className="h-10 block w-full py-[7px] px-[14px] rounded bg-white test-[#555]"
                value={props.jobTitle}
                onChange={handleOn_chng}
                reference={click_ref_3}
                required={true}
                style={
                  errJob.errorMessage == null
                    ? { border: '1px solid #666' }
                    : { border: '1px solid #e60014' }
                }
                onClick={() => {
                  setJobEnable(true);
                }}
              />
              {errJob.errorMessage && (
                <div className="h-auto w-full float-left bg-[#E60014] text-white mt-2.5 relative table mb-[15px]">
                  <span className="px-0 bg-[url('https://my.bt.com/s/apps/appsconnectedcare/img/up_icon_red.png')] bg-no-repeat absolute -top-[10px] left-2.5 w-[24px] h-[11px] mt-0 mr-0 mb-2.5 ml-2.5"></span>
                  <div className="pt-2.5 pr-2.5 pb-2.5 pl-[15px]">
                    <span className="left-[15px] py-0 h-10 w-10 m-0 text-[.95em]">
                      {' '}
                      {errJob.errorMessage}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="w-full my-5 mx-0 relative table border-separate">
            <Label
              className="text-right leading-[40px] pr-2.5 pl-0 w-[31.25%] mb-[5px] float-left"
              LabelText="Phone"
            />

            <div className="px-0 w-[31.25%] table">
              <InputField
                id="ph"
                type="text"
                className="h-10 block w-full py-[7px] px-[14px] rounded bg-white test-[#555]"
                value={props.phone}
                onChange={handleOn_change}
                reference={click_ref_4}
                required={true}
                style={
                  errPh.errorMessage == null
                    ? { border: '1px solid #666' }
                    : { border: '1px solid #e60014' }
                }
                onClick={() => {
                  setPhEnable(true);
                }}
              />
              {errPh.errorMessage && (
                <div className="h-auto w-full float-left bg-[#E60014] text-white mt-2.5 relative table mb-[15px]">
                  <span className="px-0 bg-[url('https://my.bt.com/s/apps/appsconnectedcare/img/up_icon_red.png')] bg-no-repeat absolute -top-[10px] left-2.5 w-[24px] h-[11px] mt-0 mr-0 mb-2.5 ml-2.5"></span>
                  <div className="pt-2.5 pr-2.5 pb-2.5 pl-[15px]">
                    <span className="left-[15px] py-0 h-10 w-10 m-0 text-[.95em]">
                      {' '}
                      {errPh.errorMessage}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="block"></div>
          <div className="my-5 mx-0 w-full" style={{ borderBottom: '#ddd solid 1px' }}></div>
          <div className="float-right">
            <Button
              className={
                props.title && props.fname && props.lname && props.jobTitle && props.phone
                  ? 'bg-black  text-white border-black py-[11px] px-5 my-[5px] mx-0 w-full text-center h-10 text-[.9em] rounded-md inline-block'
                  : 'bg-[#808080] cursor-not-allowed  text-white border-[#808080] opacity-50 py-[11px] px-5 my-[5px] mx-0 w-full text-center h-10 text-[.9em] rounded-md inline-block'
              }
              disabled={
                !props.title && !props.fname && !props.lname && !props.jobTitle && !props.phone
              }
              onclick={() => {
                props.setSaveUserDetails(true);
              }}
              buttonText="Continue"
            ></Button>
          </div>
        </div>
      )}
    </Fragment>
  );
};
export default UserDetails;
