import React from 'react';
interface IButton {
  className: string;
  buttonText: string;
  disabled?: boolean;
  onclick: (e: React.FormEvent) => void;
}

const Button: React.FC<IButton> = ({ className, buttonText, onclick }) => {
  return (
    <button className={className} onClick={onclick}>
      {buttonText}
    </button>
  );
};
export default Button;
