import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
  ICognitoUserPoolData
} from 'amazon-cognito-identity-js';
export default class AwsCognito {
  objCognitoUserPool: CognitoUserPool | undefined;
  attributeList: CognitoUserAttribute[] = [];
  validationList: CognitoUserAttribute[] = [];
  // constructor() {}

  public CognitoUserPool(): CognitoUserPool {
    if (this.objCognitoUserPool == null) {
      this.objCognitoUserPool = new CognitoUserPool(this.poolData());
      return this.objCognitoUserPool;
    }
    return this.objCognitoUserPool;
  }

  public addAttributetoList(attributeName: string, attributeValue: string): void {
    this.attributeList.push(
      new CognitoUserAttribute({
        Name: attributeName,
        Value: attributeValue
      })
    );
  }

  public createUser(useName: string, password: string, userSatus: any): any {
    this.CognitoUserPool().signUp(
      useName,
      password,
      this.attributeList,
      this.validationList,
      function (err, result) {
        console.log('Signup---->');
        if (err != null) {
          console.log('Signup---->error');
          alert(err.message.length > 0 || JSON.stringify(err));

          userSatus({
            status: 403,
            message: 'User Not Created'
          });
        } else {
          const cognitoUser = result!.user;
          console.log('user name is ' + cognitoUser.getUsername());
          userSatus({
            status: 201,
            message: cognitoUser
          });
        }
      }
    );
  }

  public confirmRegistration(Username: string, emailOtp: string, userStatus: any): any {
    return this.createCognitoUser(Username).confirmRegistration(
      emailOtp,
      true,
      function (err, result) {
        if (err) {
          userStatus({
            status: 403,
            errorMessage: err.message || JSON.stringify(err)
          });
        } else {
          userStatus({
            status: 201,
            errorMessage: result
          });
        }
      }
    );
  }

  public doLogin(userName: string, password: string, userSatus: any): any {
    this.createCognitoUser(userName).authenticateUser(
      this.createAuthenticationDetails(userName, password),
      {
        onSuccess: function (result) {
          console.log('onSuccess---->');
          userSatus({
            status: 201,
            errorMessage: result.getAccessToken().getJwtToken()
          });
        },

        onFailure: function (err) {
          console.log('onFailure---->');
          userSatus({
            status: 403,
            errorMessage: err.message || JSON.stringify(err)
          });
        }
      }
    );
  }

  private createCognitoUser(Username: string): CognitoUser {
    return new CognitoUser({
      Username,
      Pool: this.CognitoUserPool()
    });
  }

  private poolData(): ICognitoUserPoolData {
    return {
      UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID ?? '', // Your user pool id here
      ClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID ?? '' // Your client id here
    };
  }

  private createAuthenticationDetails(userName: string, password: string): AuthenticationDetails {
    return new AuthenticationDetails({
      Username: userName,
      Password: password
    });
  }
}
