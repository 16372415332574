import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

export default function UserSuccessTemplate(props: any): any {
  return (
    <Fragment>
      {' '}
      <div className="block mb-[30px]">
        <h2
          className="py-5 px-[30px] my-0 -mx-[30px] bg-[#666] text-white cursor-pointer text-[1em] z-[1] min-h-[45px]"
          style={{ borderTop: '1px solid #fff' }}
        >
          <span className="text-white block">Activate your User Name</span>

          <span className="block float-right h-4 text-left">
            <span className="flot-left py-4 px-[5px] text-[white] inline-block relative -top-[49px] underline"></span>
            <span
              className="inline-block relative -top-[34px]"
              style={{ transform: 'translate(0%,1%)' }}
            ></span>
          </span>
        </h2>
      </div>
      <div className="block">
        <h3 className="pr-[25%] -mt-[14px] mb-2.5 text-[21px] leading-[1.5em] text-[#333]">
          You're almost done
        </h3>
        <p className="mt-0 mr-0 mb-2.5 ml-0 text-[14px]">
          Thanks for registering in our partner program, to verify your accout click on the{' '}
          <Link className="text-[blue] text-[16]" to={`/otp?username=${props.inputEmail}`}>
            Link
          </Link>
        </p>
      </div>
    </Fragment>
  );
}
