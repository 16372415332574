import React, { Fragment } from 'react';
import Button from '../../library/Button/button';
import UserRow from '../UserRow';
import Search from './Search';
import Show from './Show';

const UserList: React.FC<any> = () => {
  // Dummy function for create user button.
  const createUser = (): void => {
    alert('Create user alert');
  };
  return (
    <Fragment>
      <div style={{ width: '100%' }}>
        <div className="bg-white block" style={{ paddingInline: '2px 2px', width: '100%' }}>
          <div className="flex justify-between">
            <h1 className="text-black text-xl leading-tight font-medium mb-0">
              Organisation Users
            </h1>
            <Button
              className="mb-0 h-[33px] text-[#fff] text-[16px] w-44 cursor-pointer bg-black rounded-[10px]"
              buttonText="Create User"
              onclick={createUser}
            />
          </div>
          <br />
          <hr />
          <br />
          <Search />
          <Show />
          <br />
          <br />
          <div className="w-full">
            <table className="w-full whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300">
              <thead className="bg-[#f8f8f8]">
                <tr className="text-black text-left">
                  <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                    {' '}
                    Display Name{' '}
                  </th>
                  <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                    {' '}
                    UserName{' '}
                  </th>
                  <th className="font-semibold text-sm uppercase px-6 py-4 text-center"> Role </th>
                  <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                    {' '}
                    Status{' '}
                  </th>
                  <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                    {' '}
                    Action{' '}
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                <UserRow
                  name={'Arnab'}
                  email={'Arnab@gmail.com'}
                  role={'Developer'}
                  status={'Approved'}
                />
                <UserRow
                  name={'Anku'}
                  email={'Singh@gmail.com'}
                  role={'Developer'}
                  status={'Approved'}
                />
                <UserRow
                  name={'Nilesh'}
                  email={'Nilesh@gmail.com'}
                  role={'Developer'}
                  status={'Approved'}
                />
                <UserRow
                  name={'Megh'}
                  email={'Megh@gmail.com'}
                  role={'Developer'}
                  status={'Approved'}
                />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UserList;
