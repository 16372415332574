import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import UserEmailInfo from '../components/register/userEmailInfo';
import UserDetails from '../components/register/userDetails';
import PartnerCompanyDetails from '../components/register/partnerComapnyDetails';
import UserSuccessTemplate from '../components/register/userSuccessTemplate';
import { Link } from 'react-router-dom';
import AwsCognito from '../utils/AwsCognito';

const Register: React.FC<any> = () => {
  interface IError {
    errorMessage: string | null;
    errorConfirmMessahe: string | null;
  }
  const [inputEmail, setInputEmail] = useState<string>('');
  const [isEmailValid, setisEmailValid] = useState<boolean>(false);
  const [isEmailEnable, setEmailEnable] = useState<boolean>(false);
  const [error, setError] = useState<IError>({
    errorMessage: null,
    errorConfirmMessahe: null
  });
  const [isSaveUserDetails, setSaveUserDetails] = useState<boolean>(false);
  const [isSaveUserPcd, setSaveUserPcd] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [fname, setFname] = useState<string>('');
  const [lname, setLname] = useState<string>('');
  const [jobTitle, setjobTitle] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [password, setPassword]: any = useState({});
  const [partnerCompanyName, setpartnerCompanyName] = useState<string>('');
  const [companyHeadQuaterAddress, setcompanyHeadQuaterAddress] = useState<string>('');
  const [description, setdescription] = useState<string>('');
  const [doj, setDoj] = useState<string>('');
  const [userCreatedStatus, setuserCreatedStatus]: any = useState({
    isSusuccessfull: false,
    errMessage: ''
  });

  useEffect(() => {
    document.addEventListener('click', handleChange, isEmailEnable);
    return () => {
      document.removeEventListener('click', handleChange, isEmailEnable);
    };
  }, [isEmailEnable]);

  const clickRef: any = useRef(null);

  function isValidEmail(email: string): boolean {
    const regex =
      /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"]+\.)+[^<>()[\]\\.,;:\s@\\"]{2,})$/i;

    return regex.test(email);
  }

  const handleChange = (event: any): any => {
    if (clickRef.current && !clickRef.current.contains(event.target) && isEmailEnable) {
      if (!isValidEmail(clickRef.current.value)) {
        setisEmailValid(false);
        setError({
          errorMessage: `Username has to be an email address.
        Please try again.`,
          errorConfirmMessahe: ''
        });
      } else {
        setisEmailValid(true);
        setError({ errorMessage: null, errorConfirmMessahe: null });
      }
    } else {
      setError({ errorMessage: null, errorConfirmMessahe: null });
    }
  };

  const handleOnChange = (event: any): any => {
    if (event.target.value !== undefined) {
      if (event.target.id === 'userEmail') {
        setInputEmail(event.target.value);
        isValidEmail(event.target.value) ? setisEmailValid(true) : setisEmailValid(false);

        setError({
          errorMessage: null,
          errorConfirmMessahe: error.errorConfirmMessahe
        });
      } else {
        setInputEmail(inputEmail);
        setError({
          errorMessage: error.errorMessage,
          errorConfirmMessahe: null
        });
      }
    }
  };

  const createUser = async () => {
    const awsCognitoObj = new AwsCognito();
    awsCognitoObj.addAttributetoList('custom:name', `${title} ${fname} ${lname}`);
    awsCognitoObj.addAttributetoList('email', inputEmail);
    awsCognitoObj.addAttributetoList('custom:phone_number', phone);
    awsCognitoObj.addAttributetoList('custom:job_title', jobTitle);
    awsCognitoObj.addAttributetoList('custom:company_address', companyHeadQuaterAddress);
    awsCognitoObj.addAttributetoList('custom:joining_reason', description);
    awsCognitoObj.createUser(inputEmail, password.password, (userstatus: any) => {
      if (userstatus.status === 201) {
        setuserCreatedStatus({
          isSusuccessfull: true,
          errMessage: ''
        });
      } else {
        setuserCreatedStatus({
          isSusuccessfull: false,
          errMessage: 'User Name already exists..'
        });
      }
    });
  };

  return (
    <div className="visible block box-border text-[16px] h-full w-full flex-1">
      {/* Header Start */}
      <Header />
      {/* Header End */}

      <div
        className={
          userCreatedStatus.isSusuccessfull
            ? 'max-w-[1220px] pl-[30px] pr-[30px] bg-white mr-auto ml-auto min-h-[810px]'
            : 'max-w-[1220px] pl-[30px] pr-[30px] bg-white mr-auto ml-auto'
        }
      >
        {userCreatedStatus.isSusuccessfull === false && (
          <div>
            {' '}
            <div className="block">
              <Link className="cursor-pointer font-[400] text-black no-underline" to={''}>
                <div className="h-2.5"></div>
                <i
                  className="relative inline-block -top-[2px] w-[8px] h-[8px] border-t-[1px] border-r-[1px] mr-[0.5em] ml-0.5 border-r-[bt-purple] border-t-[bt-purple]"
                  style={{
                    transform: 'rotate(228deg)',
                    content: ''
                  }}
                ></i>
                Back to Dashboard
              </Link>
            </div>
            <UserDetails
              isSaveUserDetails={isSaveUserDetails}
              title={title}
              setTitle={setTitle}
              fname={fname}
              setFname={setFname}
              lname={lname}
              setLname={setLname}
              jobTitle={jobTitle}
              setjobTitle={setjobTitle}
              setPhone={setPhone}
              phone={phone}
              setSaveUserDetails={setSaveUserDetails}
            />
            <PartnerCompanyDetails
              isSaveUserDetails={isSaveUserDetails}
              isSaveUserPcd={isSaveUserPcd}
              setSaveUserPcd={setSaveUserPcd}
              setpartnerCompanyName={setpartnerCompanyName}
              partnerCompanyName={partnerCompanyName}
              companyHeadQuaterAddress={companyHeadQuaterAddress}
              setcompanyHeadQuaterAddress={setcompanyHeadQuaterAddress}
              doj={doj}
              setDoj={setDoj}
              setdescription={setdescription}
              description={description}
              setSaveUserDetails={setSaveUserDetails}
            />
            <UserEmailInfo
              isSaveUserDetails={isSaveUserDetails}
              isSaveUserPcd={isSaveUserPcd}
              inputEmail={inputEmail}
              handleOnChange={handleOnChange}
              clickRef={clickRef}
              error={error}
              isEmailValid={isEmailValid}
              password={password}
              setPassword={setPassword}
              setSaveUserPcd={setSaveUserPcd}
              createUser={createUser}
              setEmailEnable={setEmailEnable}
            />
          </div>
        )}

        {/* Start Success tab */}

        {userCreatedStatus.isSusuccessfull && <UserSuccessTemplate inputEmail={inputEmail} />}

        {/* End Success tab */}
      </div>

      {/* <Footer /> */}
      <div className="w-full" style={isSaveUserDetails ? { padding: '64px' } : { padding: '0px' }}>
        <Footer />
      </div>
    </div>
  );
};

export default Register;
